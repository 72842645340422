const API_URL =
  typeof window !== 'undefined'
    ? window.location.hostname === 'www.hoost.tw'
      ? 'https://www.hoost.tw/api/v1'
      : 'https://dev.hoost.tw/api/v1'
    : process.env.ENV === 'dev'
      ? 'https://dev.hoost.tw/api/v1'
      : 'https://www.hoost.tw/api/v1';

export default API_URL;
