enum ErrorType {
  TOKEN_EXPIRED,
  RESULT_FALSE,
  COUPON_NOT_START_YET = 'un_start_yet',
  COUPON_EXPIRED = 'expired',
  COUPON_LIMITED = 'limited',
  COUPON_NOT_FOUND = 'not_found',
}

export default ErrorType;
