import Episode from '@models/Episode';
import { Method } from 'axios';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import API_URL from '../config';
import ErrorType from '../models/error';
import ListenHistory from '../models/ListenHistory';
import { Podcast } from '@models/Podcast';
import Cookies from 'js-cookie';
import {
  AddCommentResponse,
  CouponValidateResponse,
  CreateCheckoutResponse,
  GetCommentsResponse,
  GetOrderListResponse,
  LoginResponse,
} from '@models/responses';
import { Topic } from '@models/Topic';
import { loginAction } from '../redux/action';
import useSession from './useSession';

interface CheckoutForm {
  name: string;
  email: string;
  city: string;
  region: string;
  phone: string;
  postal_code: string;
  address: string;
  period_discount_day?: number;
}

const useAPI = () => {
  const session = useSession();
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies();

  const callAPI = (
    url: string,
    method: Method = 'GET',
    body?: string | FormData,
    json?: boolean
  ) => {
    const headers = new Headers();

    if (method === 'POST' && json)
      headers.append('Content-Type', 'application/json');

    if (session.isLoggedIn) headers.append('auth-token', session.token);

    return fetch(API_URL + url, {
      method,
      body,
      headers
    });
  };

  return {
    login: async (params: any) => {
      const formData = new FormData();
      for (let key in params) {
        if (params.hasOwnProperty(key)) formData.append(key, params[key]);
      }
      const res = await callAPI('/login', 'POST', formData);
      const parsedResponse = (await res.json()) as LoginResponse;
      if (!parsedResponse.result) throw parsedResponse.message;
      session.login(parsedResponse.auth_token, parsedResponse.user);
      setCookies('p-auth', parsedResponse.p_auth);
      dispatch(loginAction(parsedResponse.auth_token, parsedResponse.user));
    },

    checkout: async (
      topicId: string,
      skuId: string,
      purchaseType: 'credit_card' | 'cvs',
      form: CheckoutForm,
      allowShareData: boolean,
      coupon?: string
    ) => {
      const formData = new FormData();
      formData.append('topic_id', topicId);
      formData.append('sku_id', skuId);
      formData.append('purchase_type', purchaseType);

      formData.append('email', form.email);
      formData.append('city', form.city);
      formData.append('region', form.region);
      formData.append('phone', form.phone);
      formData.append('address', form.address);
      formData.append('postal_code', form.postal_code);
      formData.append('name', form.name);
      formData.append('allow_share_data', allowShareData.toString());
      if (!isNaN(form.period_discount_day)) formData.append(
        'period_discount_day',
        form.period_discount_day.toString());

      if (coupon) formData.append('coupon_code', coupon);

      if (cookies.rec_code !== undefined)
        formData.append('rec_code', cookies.rec_code);

      const res = await callAPI('/checkout/create', 'POST', formData);

      const parsedRes: CreateCheckoutResponse = await res.json();

      if (res.status === 401) throw ErrorType.TOKEN_EXPIRED;
      if (parsedRes.result === false) throw parsedRes;

      return parsedRes;
    },

    getOrderList: async () => {
      const res = await callAPI('/order/list');
      if (!res.ok) throw ErrorType.RESULT_FALSE;
      const parsedRes: GetOrderListResponse = await res.json();
      if (!parsedRes.result) throw ErrorType.RESULT_FALSE;
      return parsedRes.datas;
    },

    getComments: async (episodeId: string) => {
      const res = await callAPI(
        `/comment/get_comments?episode_id=${episodeId}`
      );
      if (!res.ok) throw ErrorType.RESULT_FALSE;
      const parsedRes: GetCommentsResponse = await res.json();
      if (!parsedRes.result) throw ErrorType.RESULT_FALSE;
      return parsedRes.datas;
    },

    addComments: async (
      episodeId: string,
      content: string,
      replyCommentId?: string
    ) => {
      const req = new FormData();
      req.append('episode_id', episodeId);
      req.append('content', content);
      if (replyCommentId) req.append('reply_comment_id', replyCommentId);

      const res = await callAPI('/comment/add_comment', 'POST', req);
      if (!res.ok) throw ErrorType.RESULT_FALSE;
      const parsedRes: AddCommentResponse = await res.json();
      if (!parsedRes.result) throw ErrorType.RESULT_FALSE;
      return parsedRes.comment;
    },

    validateCoupon: async (
      topicId: string,
      code: string,
      skuId: string,
      purchaseType: 'cvs' | 'credit_card'
    ) => {
      const req = new FormData();
      req.append('topic_id', topicId);
      req.append('sku_id', skuId);
      req.append('code', code);
      req.append('purchase_type', purchaseType);

      const res = await callAPI('/coupon/validate', 'POST', req);
      if (!res.ok) throw ErrorType.RESULT_FALSE;
      const parsedRes: CouponValidateResponse = await res.json();
      if (!parsedRes.valid) throw new Error(parsedRes.message);
      return parsedRes;
    },

    /** 取得已訂閱課程列表 */
    getSubscribedTopicList: async () => {
      const res = await callAPI('/user/get_subscribe_topics');
      const parsedRes = await res.json();
      if (!parsedRes.result) throw ErrorType.RESULT_FALSE;
      return parsedRes.datas as Topic[];
    },

    /** 取得課程詳情及課程單集列表 */
    getTopicDetail: async (topicId: string) => {
      const res = await callAPI(
        `/topic/get_topic?id=${topicId}&show_episode=true`
      );
      const parsedRes = await res.json();
      if (!parsedRes.result) throw ErrorType.RESULT_FALSE;
      parsedRes.topic?.episodes?.map((ep: Episode) => {
        ep.is_purchased = parsedRes.topic.is_purchased;
      });
      return parsedRes as { podcast: Podcast; topic: Topic };
    },

    /** 取得收聽紀錄 */
    getListenHistories: async (option: {
      topicId?: string;
      episodeId?: string;
    }) => {
      let url = '/episode/get_listen_histories?';
      if (option.topicId) url += '&topic_id=' + option.topicId;
      if (option.episodeId) url += '&episode_id=' + option.episodeId;
      const res = await callAPI(url);
      const parsedRes = await res.json();
      if (!parsedRes.result) throw ErrorType.RESULT_FALSE;
      return parsedRes.datas as ListenHistory[];
    },

    episode: {
      getMany: async function (podcastId: string, offset: number) {
        const res = await callAPI(
          `/episode/all?offset=${offset}&&podcast_id=${podcastId}`,
          'GET'
        );
        const parsedRes = await res.json();
        if (!parsedRes.result) {
          throw new Error(parsedRes);
        }
        return parsedRes.datas as Episode[];
      },
      get: async function (episodeId: string) {
        const res = await callAPI('/episode/get_detail?id=' + episodeId);
        const parsedRes = await res.json();
        if (!parsedRes.result) {
          throw new Error(parsedRes);
        }
        return parsedRes.episode as Episode;
      }
    },

    topic: {

      getAll: async (offset: number) => {

        const res = await callAPI('/topic/list?is_free=false&offset=' + offset);
        const parsedRes = await res.json();
        if (!parsedRes.result) {
          throw new Error(parsedRes);
        }
        return parsedRes.datas as Topic[];
      }

    },

    /* 事件追蹤 */
    eventTrack: {
      report: async (event: TrackEventType, topicId: string) => {
        const urlParams = new URLSearchParams(window.location.search);
        const recCode = urlParams.get('rec_code');

        let userId = session.user?.id;
        const LS = window.localStorage.getItem('userData');
        if (!userId && LS) {
          userId = JSON.parse(LS).id;
        }

        await callAPI(
          '/t/c',
          'POST',
          JSON.stringify({
            name: event,
            user_id: userId,
            platform: 'web',
            location: navigator.language,
            values: {
              uuid: Cookies.get('uuid'),
              topic_id: topicId,
              recommend_code: recCode,
              user_agent: navigator.userAgent,
              testing_group: Cookies.get('testingGroup')
            },
          }),
          true
        );
      },
    },
  };
};

export enum TrackEventType {
  TOPIC_OPENED = 'topic_opened',
  PURCHASE_BUTTON_CLICKED = 'purchase_button_clicked'
}

export default useAPI;
